import { Link } from "gatsby"
import React from "react"

interface Props {
  item: {
    title: string
    url: string
    icon: string
    description: string
  }
}

export const Feature = ({ item }: Props) => (
  <article className="feature column is-half-tablet is-one-third-desktop is-one-quarter-widescreen">
    <Link className="box is-special has-text-centered" to={item.url}>
      <img
        alt={item.title}
        style={{ maxWidth: 120 }}
        className="icon"
        src={`/img/icons/${item.icon}`}
      />
      <h1 className="is-size-4 has-text-weight-bold has-text-centered mb-4">
        {item.title}
      </h1>
      <p className="has-text-centered">{item.description}</p>
    </Link>
  </article>
)
