import { graphql, Link } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import { Feature as FeatureIndex } from "../components/common/Feature"
import Visible from "../components/common/Visible"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
        features: Array<{
          title: string
          description: string
          icon: string
        }>
        testimony: {
          text: string
          author: string
          url: string
        }
      }
    }
    featuresIndex: {
      frontmatter: {
        features: Array<{
          title: string
          description: string
          url: string
          icon: string
        }>
      }
    }
  }
}

const Feature = ({ item }: any) => (
  <article className="column is-6 py-6">
    <h1 className="is-size-2 has-text-weight-medium mb-6">{item.title}</h1>
    <div className="columns is-mobile">
      <div className="column is-3 has-text-centered">
        <img
          className="icon img-light"
          src={`/img/icons/${item.icon}.svg`}
          alt=""
        />
        <img
          className="icon img-dark"
          src={`/img/icons/${item.icon}-dark.svg`}
          alt=""
        />
      </div>
      <div className="column is-9 pr-6">
        <HTMLContent
          className="links-animate"
          content={item.description}
        ></HTMLContent>
      </div>
    </div>
  </article>
)

// markup
const WhySpringTemplate = (props: Props) => {
  const featuresIndex = props.data.featuresIndex.frontmatter.features
  const features = props.data.page.frontmatter.features
  const testimony = props.data.page.frontmatter.testimony
  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className="why-spring"
    >
      <div className="hero-why has-background-light">
        <div className="container">
          <div className="extra">
            <img
              className="img-light"
              src={"/img/extra/why-spring.svg"}
              alt=""
            />
            <img
              className="img-dark"
              src={"/img/extra/why-spring-dark.svg"}
              alt=""
            />
          </div>
          <div className="brand">
            <div className="content">
              <HTMLContent
                content={props.data.page.html}
                className={"markdown links-animate"}
              ></HTMLContent>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="content py-6">
          <div className="testimony p-6">
            <div className="extra-0">
              <img
                className="img-light"
                src={"/img/extra/quote-2.svg"}
                alt=""
              />
              <img
                className="img-dark"
                src={"/img/extra/quote-2-dark.svg"}
                alt=""
              />
            </div>
            <div className="testimony-text mb-5 is-size-4">
              {testimony.text}
            </div>
            <div className="testimony-author">
              <div className="has-text-weight-bold">{testimony.author}</div>
              <div>
                <a href={testimony.url}>Watch now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="content pt-3 pb-6">
          <div className="columns is-centered is-multiline">
            {features.map((feature: any, index: number) => (
              <Feature item={feature} key={`feature-${index}`} />
            ))}
          </div>
        </div>
      </div>

      <div className="home has-background-light  has-big-border-bottom">
        <div className="container py-6">
          <div className="content pt-5 pb-6">
            <div className="features">
              <h1 className="is-size-2 mb-6 mt-4 has-text-weight-medium">
                What Spring can do?
              </h1>
              <Visible className="columns is-centered is-multiline features">
                {featuresIndex.map((featureIndex: any, index: number) => (
                  <FeatureIndex
                    item={featureIndex}
                    key={`featureIndex-${index}`}
                  />
                ))}
              </Visible>
            </div>
          </div>
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default WhySpringTemplate

// graphQL queries
export const pageQuery = graphql`
  query WhySpringTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        features {
          title
          description
          icon
        }
        testimony {
          text
          author
          url
        }
      }
    }
    featuresIndex: markdownRemark(
      frontmatter: { templateKey: { eq: "index-template" } }
    ) {
      frontmatter {
        features {
          title
          description
          url
          icon
        }
      }
    }
  }
`
